.sitesCardHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-border-b yst-border-slate-200 yst-p-6 yst-space-y-4;
}

.sitesLabel {
	@apply yst-pb-2.5 yst-border-b yst-border-slate-200;
}

li.sitesListItem {
	@apply yst-flex yst-items-center yst-justify-between yst-py-2.5 yst-border-b yst-border-slate-200;
}

li.noSitesListItem {
	@apply yst-pt-2.5 yst-italic;
}

div.sitesButtonWrapper {
	@apply yst-space-x-3;
}

button.sitesButton {
	@apply yst-gap-1;
}

.sitesButtonIcon {
	@apply yst-w-4 yst-h-4;
}
