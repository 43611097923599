.userInfoContainer {
	display: flex;
}

.userName{
	font-weight:bold;
}

.userInfo {
  // Firefox needs this for user-email break word to work.
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--color_gray_900);
}

.userEmail {
	font-size: 13px;
	font-weight: 400;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}

.logOut {
	button {
		display: flex;
		align-items: center;
		text-decoration: none;
		font-weight: 500;

		span {
			color: var(--text-color-purple);
		}

		svg {
			fill: var(--text-color-purple);
			height: 14px;
			width: 14px;
			margin-left: 8px;
		}
	}
}
