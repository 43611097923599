ul.upsellList {
	@apply yst-space-y-4;
}

li.upsellItem {
	@apply yst-flex yst-gap-4 yst-items-start yst-border-b yst-border-slate-200 yst-pb-4;

	&:last-of-type {
		@apply yst-border-none yst-pb-0;
	}
	
	a{
		@apply yst-font-medium;
	}
}

img.upsellImage {
	@apply yst-w-12 yst-h-12 yst-shrink-0;
}
