.installWrapper {
	@apply
	yst-mb-12;
}

.header {
	@apply
	yst-py-6
	yst-text-white;
	background: linear-gradient(60deg, #5d237a 0%, #a61e69 84%, #a61e69 100%);

	.headerContainer {
		@apply
		yst-flex
		yst-flex-col
		md:yst-flex-row
		md:yst-justify-between
		yst-py-6
		md:yst-py-0;
	}

	.headerLogo {
		@apply
		yst-self-center;
	}

	.headerImage {
		@apply
		yst-hidden
		md:yst-block
		yst-mt-8
		yst-self-end;
	}
}

.wideContent {
	@apply
	yst-mx-6
	lg:yst-mx-auto
	lg:yst-w-5/6
	xl:yst-w-4/6;
}

.narrowContent {
	@apply
	sm:yst-w-5/6
	md:yst-w-4/6
	lg:yst-w-3/6
	xl:yst-w-2/6
	yst-mx-6
	sm:yst-mx-auto;
}

.steps {
	@apply
	yst-flex
	yst-my-8
	yst-px-4
	yst-py-2
	yst-grid-cols-4
	yst-rounded-lg;

	counter-reset: steps;

	.step {
		@apply
		yst-relative
		yst-flex
		yst-grow
		yst-items-center
		yst-opacity-70;

		&::after {
			content: "";
			@apply
			yst-absolute
			yst-h-px
			yst-bg-slate-300
			yst-left-0
			yst-right-3
			yst-z-10;
		}

		>span {
			@apply
			yst-bg-white
			yst-pr-3
			yst-pl-2
			yst-z-20;
		}

		&:last-child {
			@apply yst-grow-0
		}
	}

	.step::before {
		content: counter(steps);
		counter-increment: steps;
		@apply
		yst-bg-slate-200
		yst-rounded-full
		yst-min-w-8
		yst-min-h-8
		yst-flex
		yst-justify-center
		yst-items-center
		yst-z-20;
	}

	.activeStep {
		@apply
		yst-opacity-100;
	}

	.activeStep::before {
		@apply
		yst-bg-primary-500
		yst-text-white;
	}
}

.textCenter {
	@apply
	yst-text-center;
}

.ySpacing {
	@apply
	yst-space-y-6;
}

.successImage {
	@apply
	yst-w-52
	yst-mx-auto;
}

.iframe {
	@apply
	yst-h-[320px]
	yst-w-full;
}
