  .subscriptionSection {
    .detailsLine {
      @apply yst-border-b
        yst-border-slate-200;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 0;

      .lineTitle {
        flex: 0 1 auto;
        width: 320px;
      }

      .lineValue {
        flex: 1 0 auto;
      }

      .lineAction {
        flex: 0 1 auto;
      }

      .statusValue {
        @extend .lineValue;
      }
    }
  }
