.pageSection {
	@apply yst-grid xl:yst-grid-cols-3 yst-gap-8 xl:yst-gap-12 yst-py-8 yst-border-b yst-border-slate-200;

	&.noBorder {
		@apply yst-border-0 yst-pb-0;
	}
}

section {
	@apply yst-max-w-5xl;
}

.sectionTitle {
	@apply yst-col-span-1 yst-max-w-screen-sm;

	p {
		@apply yst-mt-2;
	}
}

.sectionContent {
	@apply yst-col-span-2 yst-space-y-6 yst-max-w-lg;
}

.externalLink {
	@apply yst-inline-flex;
}

.externalLinkIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5 yst-translate-y-0.5;
}

.composerTokenModal {
	@apply yst-min-w-[340px] sm:yst-min-w-[576px]; // As per UI lib modal width
}
