.sectionWrapper {
  @apply
    -yst-mt-6
    yst-p-6
    yst-space-y-4
    yst-mb-6;

  margin-left: calc(-1 * var(--gap));
  margin-bottom: var(--gap);
  width: calc(100% + var(--gap) * 2);

  .children {
    @apply yst-w-full
      lg:yst-w-2/3;
  }


  &.fullWidth {
    .children {
      width: 100%;
    }
  }
}