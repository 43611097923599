.siteHeader {
	@apply
	yst-flex
	yst-flex-col;

	.siteName {
		@apply
		yst-m-0
		yst-mb-4;
	}

	.buttonArea {
		@apply
		yst-flex
		yst-flex-col
		sm:yst-flex-row
		yst-gap-3
		yst-items-start;

		svg {
			@apply
			yst-text-slate-600
			yst-w-3.5
			yst-h-3.5
			yst-ml-2;
		}
	}
}
