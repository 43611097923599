.form {
	@apply
	yst-w-full
	yst-flex
	yst-flex-col
	yst-items-start
	yst-gap-4;

	>div {
		@apply
		yst-w-full
		yst-text-left;
	}
}
