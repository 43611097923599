  .subscriptionActionSection {
    .detailsLine {
      @apply yst-border-b
        yst-border-slate-200;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 0;
      text-transform: capitalize;

      .lineValue {
        flex: 1 0 auto;
      }

      .lineAction {
        flex: 1 0 auto;
      }
    }
  }
