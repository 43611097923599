.searchLabel {
	@apply yst-sr-only;
}

.searchInputWrapper {
	@apply yst-relative yst-mb-6;
}

.searchInput {
	@apply yst-block;
	padding-left: 2.5rem !important;
}

.searchIcon {
	@apply yst-absolute yst-w-4 yst-h-4 yst-text-slate-500 yst-z-10 yst-left-3.5 yst-top-[13px];
}
