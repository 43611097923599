.header {
	@apply -yst-mt-6 -yst-mx-6 yst-mb-6 yst-border-b yst-border-slate-200 yst-p-6 yst-space-y-4;
}

.form {
	@apply yst-space-y-8;
}

.actions {
	@apply yst-flex yst-justify-between;

	.actionsLeft {
		@apply yst-space-x-3;
	}
}
