.downloadItemHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-border-slate-200 yst-p-6 yst-space-y-4 yst-text-center;
}

.downloadItemImage {
	@apply yst-w-24 yst-h-24 yst-mx-auto;
}

.downloadItemMeta {
	@apply yst-flex yst-flex-col yst-items-center yst-justify-center yst-space-y-1 yst-mb-5;
}

.downloadItemLink {
	@apply yst-inline-flex yst-items-center;
}

.downloadItemLinkIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5;
}

.downloadItemButtonIcon {
	@apply yst-inline-block yst-w-4 yst-h-4 yst-mr-1;
}
.downloadItemFooter {
	@apply yst-flex yst-flex-col yst-items-center yst-space-y-2;
}
