@use "../../config/defaults.scss" as defaults;

div.messageContainer[class~='yst-card'] {
  position: relative;
  display: block;
  margin-bottom: 1em;

  > button {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  @media screen and ( max-width: defaults.$mobileBreakpoint ) {
    padding: 0;
  }


  div.imageTextContainer {
    display: flex;
    align-items: center;
    margin-top: 0 !important;

	  .title {
		  @apply yst-text-slate-800;
		  font-weight: 500;
	  }
  }

  div.inlineBlock {
    display: inline-block;
    vertical-align: middle;
  }

  img.renewalImage {
    flex: 0 1 auto;
    max-height: 100px;
    margin-right: 24px;

    @media screen and ( max-width: defaults.$mobileBreakpoint ) {
      display: none;
    }
  }

  p.renewalItem {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
  }

  img.renewalItemImage {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }

  img.buttonIcon {
    height: 24px;
  }

  .closeButton {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
	  @apply yst-text-slate-400;
  }

  .tableContainer{
    max-width: 760px;
  }

  .table {
    flex-wrap: wrap;

    .cellLabel {
      display: none;
    }

    .header {
      td {
        font-weight: bold;
      }
    }

	  button {
		  svg {
			  max-width: 1rem;
			  min-width: 1rem;
			  margin-left: 8px;
		  }
	  }

    @media screen and ( max-width: defaults.$mobileBreakpoint ) {
      display: flex;

      .header {
        display: none;
      }

      tr, td {
        width: 100%;
      }

      .cellLabel {
        display: inline-block;
        font-weight: bold;
        margin-right: 1em;
      }

      .upsell {
        a {
          width: 100%;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
