.firstColumn {
	@apply
	yst-flex
	yst-flex-col
	yst-gap-4
	yst-items-center
	yst-justify-center
	yst-text-center
	yst-max-w-lg;

	img:nth-of-type(1) {
		@apply
		yst-mt-6
		lg:yst-mt-0
		yst-w-11/12
		md:yst-w-5/6;
	}

	img:nth-of-type(2) {
		@apply
		yst-w-40
		yst-h-40;
	}

	h1, p:not(.yst-text-field__validation) {
		@apply
		yst-w-5/6;
	}

	form {
		@apply
		yst-w-full
		yst-space-y-4
		yst-text-left;

		.actions {
			@apply
			yst-flex
			yst-flex-col
			sm:yst-flex-row
			yst-items-center
			yst-justify-between
			yst-gap-4;

			* {
				@apply
				yst-w-full
				sm:yst-w-auto;
			}
		}
	}
}
