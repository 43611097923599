.header {
	@apply
	yst-py-6
	yst-text-white;
	background: linear-gradient(60deg, #5d237a 0%, #a61e69 84%, #a61e69 100%);

	.headerContainer {
		@apply
		yst-flex
		yst-flex-col
		md:yst-flex-row
		md:yst-justify-between
		yst-py-6
		md:yst-py-0;
	}

	.headerLogo {
		@apply
		yst-self-center;
	}

	.headerImage {
		@apply
		yst-hidden
		md:yst-block
		yst-mt-8
		yst-self-end;
	}
}

.wideContent {
	@apply
	yst-mx-6
	lg:yst-mx-auto
	lg:yst-w-5/6
	xl:yst-w-4/6;
}

.pluginInstallerContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pinkText {
	color: #a61e69;
}

.greenText {
	color: #18a24a;
}

.errorText {
	color: red;
}

.boldText {
	font-weight: bold;
}

.paper {
	@apply
	yst-mt-10
	yst-max-w-2xl;
}

.fullWidth {
	width: 100%;
}

.sectionSeparator {
	margin-top: 20px;
}

.siteInputArea {
	display: flex;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #e3e3e3;
	border-radius: 0.375rem;
	background-color: #e3e3e3;
}

.rowDisplay {
	display: flex;
	flex-direction: row;
}

.inputPrefix {
	margin-right: 0.4rem;
	margin-left: 0.6rem;
	height: fit-content;
}

.siteInputField {
	&:not(:hover, :focus) {
		box-shadow: none !important;
	}

	&.withPrefix {
		padding-left: 0.25rem !important;
		border-radius: 0 0.375rem 0.375rem 0 !important;
		border-left: solid 1px var(--tw-ring-color) !important;
	}
}

.termsOfServiceLink {
	padding-left: 3px;
}

.siteForm {
	padding: 25px;

	input {
		width: 80%;
	}

	.singleRow {
		display: flex;
		flex-direction: row;
	}
}

.centralAligned {
	display: flex;
	justify-content: center;
}

.loadingState {
	display: flex;
	justify-content: center;
}

.notification {
	padding-top: 20px;
	background-color: deeppink;
}

.faqEntry {
	summary {
		position: relative;
		&::marker,
		&::-webkit-details-marker{
			content: "";
			display: none;
		}

		&::before {
			top: 2px;
			content: "+";
			position: absolute;
			left: 0;
		}
	}

	&[open]{
		summary::before {
			content: "-";
		}
	}

	&:last-child {
		margin-bottom: 0 !important;
	}
}

.answer {
	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;
	}

	ul, ol {
		padding-left: 1rem;
	}
}
