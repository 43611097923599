.sitePage {
  .separator {
    margin: 2rem -1.5rem;
    border-bottom: 1px solid gold;

    @apply yst-border-slate-200;
    width: calc( 100% + 3rem);
    display: block;
  }
}

.alertArea {
  margin-top: 20px;
}