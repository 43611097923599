.card {
	@apply yst-mt-10;
}

.debugInfoHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-border-b yst-p-4;
}

.debugInfoHeaderTitle {
	@apply yst-flex yst-items-center;
}

.debugInfoHeaderIcon {
	@apply yst-inline-block yst-w-5 yst-h-5 yst-mr-2 yst-text-primary-500;
}

ul.debugInfoList {
	@apply yst-space-y-5;
}

p.debugInfoListItemHeader {
	@apply yst-flex yst-items-center yst-justify-between yst-mb-1.5;

	>span {
		@apply yst-font-semibold;
	}
}

p.debugInfoListItemContent {
	@apply yst-whitespace-nowrap yst-overflow-hidden yst-overflow-ellipsis yst-p-2 yst-rounded yst-bg-slate-50;
}
