.cancelForm {
  padding: 2em 0 0;

  p {
    margin-bottom: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
  }

  input[type=number] {
	-moz-appearance: textfield;
  }

  .amountSelector {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    label {
      margin-top: 1em;
	  margin-bottom: 0.5em;
      width: 100%;
      flex: 2 0 auto;
      font-weight: bold;
    }

    .amountInput {
      width: 80px;
      margin: 0 8px;
	  text-align: center;
	  -webkit-appearance: none;
    }

    .amountButton {
      width: 40px;

      svg {
        @apply yst-text-slate-600;
        width: 16px;
        height: 16px;
        flex: 1 0 auto;
      }
    }
  }
}
