.content {
	@apply yst-break-words;

	p {
		@apply yst-bg-slate-50 yst-p-4 yst-rounded-md;
	}
}

.footer {
	@apply yst-flex yst-justify-end yst-space-x-6;
}
