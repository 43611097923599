.headerWrapper {
	@apply yst-border-b
	yst-border-slate-200
	-yst-mt-6
	yst-border-b
	yst-border-slate-200
	yst-p-8
	yst-space-y-4
	yst-mb-6;

	--gap: 32px;

	margin-left: calc(-1 * var(--gap));
	margin-bottom: var(--gap);
	width: calc(100% + var(--gap) * 2);

	p {
		@apply yst-w-full
		lg:yst-w-2/3;

		max-width: 666px;
	}
}
