.pageContentWrapper {
	@apply
	yst-flex
	yst-flex-col
	yst-items-center
	yst-pt-8
	yst-text-center;
}

.pageImage {
	@apply
	yst-w-96
	-yst-mb-8
	yst-mt-10;
}
