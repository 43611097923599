.upgradeTool {
	margin-top: 2em;
}

.currentSubscription {
	margin: 0 0 2em;
}

.checkmarkList {
	div {
		display: flex;
		align-items: center;
	}

	svg {
		@apply yst-fill-green-500;
		height: 14px;
		width: 14px;
		margin-right: 8px;
	}
}

.nextBilling,
.nextRenewalPrice {
	display: flex;
	position: relative;
	align-items: center;

	svg {
		height: 12px;
		width: 12px;
		margin: 0 4px;
	}

	div {
		position: relative;

		.tooltip {
			display: none;
		}

		&:hover {
			.tooltip {
				display: inline-block;
			}
		}
	}

	.newPriceSubtext {
		font-size: 10px;
	}

	> div:first-child {
		text-decoration: line-through;
	}
}

.requiredCheckmarks {
	> * {
		margin: 1em 0;
	}
}

.headerTitle{
	font-size: 15px !important;
}

.actionButtonsContainer {
	@apply
	yst-flex
	yst-items-center
	yst-justify-end
	yst-gap-4
	yst-mb-5
	yst-mt-4;
}

.toggleWrapper {
	@apply
	yst-flex
	yst-items-center
	yst-gap-3;
}
