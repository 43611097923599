/* Import main CSS including all components. */
@import "../node_modules/@yoast/ui-library/build/css/style.css";

/* Tailwind layers */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

#root, .yst-root {
	position: absolute;
	width: 100%;
	height: 100%;
}

.yst-modal__layout {
  position: relative;
}

.ReactModal__Body--open {
	overflow: hidden;
}

.auth_frame {
	height: 0;
	width: 0;
	position: absolute;
	left: -1000px;
	border: 0;
}
