.subcriptionsPage {
	div.searchWrapper {
		@apply yst-max-w-xl;
		clear: both;
	}

	p.headerText {
		max-width: 600px;
		margin-top: 1em;
	}

	.shopButton {
		margin: 3em 0 0;
	}

	.suggestedActionContainer {
		display: flex;
		justify-content: center;
		align-items:center;
	}
}
