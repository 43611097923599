.container {
	@apply
	yst-flex
	yst-flex-col
	yst-w-full lg:yst-w-2/3 xl:yst-w-1/2
	yst-mt-8
	yst-gap-1.5;

	.buttonWrapper {
		@apply
		yst-mt-3;
	}
}
