.buttonsWrapper {
	align-items: center;

	@apply
		yst-flex
		yst-gap-4;
}

.savedIcon {
	@apply
		yst-w-4
		yst-h-4
		yst-mr-2
		-yst-ml-1
		yst-opacity-75;
}

.savedMessage {
	@apply
		yst-text-sm
}
