@use "../styles.scss";
@use "../../../config/defaults.scss" as defaults;

.layoutLightGrey {
  @apply yst-bg-gray-100;
  height: 100%;
  color: #404040;
}

.main {
  &.lightGrey  {
    @apply yst-bg-gray-100;
    margin: 0;
    padding: 0 0 24px;

    @media screen and (max-width: defaults.$mediumBreakpoint) {
      position: relative;
    }
  }
}
