.content {
	@apply yst-space-y-4;
}

.externalLink {
	@apply yst-inline-flex;
}

.externalLinkIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5 yst-translate-y-0.5;
}
