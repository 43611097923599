@use "../../../config/defaults.scss" as defaults;

img.subscriptionLogo {
	@apply
	yst-inline-block
	yst-min-w-16
	yst-max-w-16
	yst-min-h-16
	yst-max-h-16
	yst-align-middle
	yst-opacity-40;

	&.hasSubscriptions {
		@apply yst-opacity-100;
	}
}

.subscriptionUsage {
	@apply
	yst-hidden;

	&.hasSubscriptions {
		@apply
		yst-flex
		yst-flex-col
		yst-items-center
		sm:yst-flex-row
		yst-gap-2;
	}
}

.provisionerSection {
	min-width: 152px;

	@media screen and (max-width: defaults.$mobileBreakpoint) {
		width: 100%;
		background-image: none;
		padding: 12px 16px;
	}
}

.column {
	@apply yst-hidden;

	&.isEnabled {
		@apply yst-inline-block;
	}

	&.fixed {
		@apply
		yst-flex;
	}
}

.manage {
	@apply yst-bg-green-500;
}

.row {
	@apply
	yst-flex
	yst-flex-col
	sm:yst-flex-row
	yst-items-center
	yst-gap-4
	yst-p-4;

	td {
		padding: 0 !important;
	}

	.firstColumn {
		@apply
		yst-w-full
		sm:yst-w-auto
		yst-flex
		yst-flex-col
		sm:yst-flex-row
		yst-gap-3
		yst-items-center;

		.hiddenToggle {
			@apply
			yst-hidden
			sm:yst-inline-block;
		}
	}

	.secondColumn {
		@apply
		yst-flex
		yst-flex-col
		yst-items-center
		sm:yst-items-start
		yst-gap-2;
	}

	.thirdColumn {
		@apply
		yst-hidden
		sm:yst-ml-auto;

		&.isEnabled {
			@apply yst-flex;
		}
	}

	.fourthColumn {
		@apply
		yst-w-full
		sm:yst-w-auto
		yst-mt-2
		sm:yst-mt-0
		sm:yst-ml-auto;

		&.isEnabled {
			@apply sm:yst-ml-4;
		}

		button, a {
			@apply
			yst-w-full
			sm:yst-w-24;
		}
	}

	.subscriptionButton {
		svg {
			@apply
			yst-ml-2
			yst-h-5
			yst-w-5;
		}
	}
}
