@use "../../../config/defaults.scss" as defaults;

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
    gap: var(--gap);
    margin-top: var(--gap);

  @media screen and (max-width: defaults.$mobileBreakpoint) {
    flex-direction: column;
    justify-content: center;
      gap: calc(var(--gap) / 2);
  }
}
