.modalTitle {
	@apply
	yst-mb-3;
}

.modalContent {
	@apply
	yst-space-y-3;
}

.modalFooter {
	@apply
	yst-flex
	yst-justify-end
	yst-mt-5
	yst-gap-3;
}
