@use "../../../config/defaults.scss" as defaults;

.hide {
	display: none;
}

.subscriptionPartOfCollapsible, // Should probably have slightly adjusted logic
.subscription {
	.general {
		display: flex;
		flex-direction: row;
		align-items: center;

		.subscriptionIcon {
			height: 32px;
			width: 32px;
			min-height: 32px;
			min-width: 32px;
		}

		.primary {
			flex: 1 0 200px;
			padding-left: 40px;

			// change 800 with defaults mobileBreakPoint
			@media screen and (max-width: defaults.$mobileBreakpoint) {
				flex-shrink: 1;
			}

			@media screen and (max-width: defaults.$mobileBreakpoint) {
				padding-left: 20px;
			}
		}
	}

	.activeSites {
		@apply yst-text-ellipsis;
		min-width: 102px;

		@media screen and (max-width: defaults.$tabletBreakpoint) {
			max-width: 120px;
		}
	}

	.styledSpace {
		min-width: 150px;

		@media screen and (max-width: defaults.$tabletBreakpoint) {
			min-width: 48px;
		}
	}

	.manageButton {
		.buttonIcon {
			display: none;
		}
		.buttonText {
			display: flex;
		}

		@media screen and (max-width: defaults.$tabletBreakpoint) {

			@apply
			yst-border-none
			yst-shadow-none
			yst-text-primary-500
			yst-bg-transparent
			yst-px-0
			yst-py-3

			hover:yst-text-primary-800
			hover:yst-bg-transparent
			focus:yst-text-primary-800
			focus:yst-ring-primary-700
			focus:yst-ring-offset-transparent
			focus:yst-ring-offset-2
			focus:yst-ring-0
			visited:yst-text-primary-800
			visited:hover:yst-text-primary-800;

			.buttonText {
				display: none;
			}
			.buttonIcon {
				display: flex;

				svg {
					@apply yst-text-slate-600;
					width: 32px;

					&:hover {
						@apply yst-text-slate-600;
					}
				}
			}
		}
	}
}

.collapsibleRow {
	.subscriptionIcon {
		height: 32px;
		width: 32px;
		min-width: 32px;
		min-height: 32px;
	}

	.collapsibleRowInfo {
		display: flex;
		flex-direction: row;
		align-items: center;

		.subscriptionName {
			padding-left: 40px;

			@media screen and (max-width: defaults.$mobileBreakpoint) {
				padding-left: 20px;
			}
		}
	}

	.expandButton {
		align-content: flex-end;

		@apply
		yst-border-none
		yst-shadow-none
		yst-text-primary-500
		yst-bg-transparent
		yst-px-0
		yst-py-3

		hover:yst-text-primary-800
		hover:yst-bg-transparent
		focus:yst-text-primary-800
		focus:yst-ring-primary-700
		focus:yst-ring-offset-transparent
		focus:yst-ring-offset-2
		focus:yst-ring-0
		visited:yst-text-primary-800
		visited:hover:yst-text-primary-800;

		.buttonIcon {
			svg {
				@apply yst-text-slate-600;
				width: 32px;

				&:hover {
					@apply yst-text-slate-600;
				}
			}
		}
	}

	.error {
		color: var(--text-color-error);
		font-weight: bold;
	}
}

@media screen and (max-width: defaults.$mobileBreakpoint) {
	.tableUsage, .tableRenewal, .tableBilling {
		display: none;
	}
}
