.activateContentWrapper {
	@apply
	yst-space-y-6;
}

.loginMessageWrapper {
	@apply
	yst-flex
	yst-flex-col
	yst-items-center;
}

.activateActionsWrapper {
	@apply
	yst-flex
	yst-items-center
	yst-justify-between;

	.activateActionsLink {
		@apply
		yst-ml-auto;
	}
}
