.form {
	@apply yst-space-y-6;
}

.button {
	@apply yst-w-full;
}

.rememberForgot {
	@apply yst-flex yst-flex-col sm:yst-flex-row yst-justify-between yst-items-start sm:yst-items-center yst-gap-4;
}
