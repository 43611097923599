.blogCardHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-border-b yst-border-slate-200 yst-p-6 yst-space-y-4;
}

ul.blogList {
	@apply yst-space-y-4;
}

li.blogItem {
	@apply yst-border-b yst-border-slate-200 yst-pb-4;

	&:last-of-type {
		@apply yst-border-none yst-pb-0;
	}
}

.blogButtonIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-1;
}
