.downloadsPageHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-border-b yst-border-slate-200 yst-p-8 yst-space-y-4 yst-mb-6;
	margin-left: -32px;
	margin-right: -32px;

	p {
		@apply yst-w-full lg:yst-w-2/3 xl:yst-w-[40%];
	}
}

.downloadsPageContentWrapper {
	@apply yst-w-full lg:yst-w-2/3 xl:yst-w-1/2;
}

.downloadsPageAlert {
	@apply yst-mb-6;
}

.downloadsPageNoDownloads {
	@apply yst-italic yst-mb-6 yst-text-slate-500;
}

.downloadPageLink {
	@apply yst-inline-flex yst-items-center;
}

.downloadPageLinkIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5;
}
