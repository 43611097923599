div.suggestedActionContainer {
	@apply
	yst-flex
	yst-flex-col
	yst-max-w-2xl;
}

img.suggestedActionImage {
	@apply
	yst-w-96
	-yst-mb-8
	yst-mt-10;
}

