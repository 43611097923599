.subscription {
  svg {
    @apply yst-text-slate-400;

	  path {
		  stroke-width: 2px;
	  }

    width: 16px;
    height: 16px;
  }

  .status {
    cursor: default;
    opacity: 1;
    text-transform: capitalize;

    &.active {
      @apply
        yst-bg-green-100
        yst-text-green-800
        yst-border-green-200;
    }
  }

  .renewLink {
    svg {
      @extend svg;
      margin-right: 8px;
		@apply yst-text-slate-400;

		path {
			stroke-width: 2px;
		}
    }
  }

  .pill {
    border-radius: 6px;
  }

  .expandableHeader {
    @apply yst-border-b
      yst-border-slate-200
      yst-text-slate-900
      yst-w-full
      lg:yst-w-2/3;

    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0;

    &.first {
      @apply yst-border-t
        yst-border-slate-200;

      margin-top: 2em;
    }

    &.isOpen {
      border-bottom: none;
    }

    svg {
      @extend svg;
    }
  }

  .sitesRow {
    display: flex;
    align-items: center;

    td {
      flex: 1 0 auto;
    }

    td.sitesTableAction {
      flex: 0 1 auto;
    }
  }

  .cancelButton {
    @apply yst-text-red-600;

    &:hover {
	  @apply yst-text-red-700;
	}
  }
}
