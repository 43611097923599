.subNavigationMenuWrapper {
	@apply
	yst-mb-6
}

.subNavigationMenuWrapperNoNegativeMargin {
	@apply
	yst-border-b
	yst-border-slate-200
	yst-mb-6;
}

.subNavigationMenuThreeItems {
	@apply
	yst-grid
	yst-grid-cols-3;
}

.subNavigationMenuTwoItems {
	@apply
	yst-grid
	yst-grid-cols-2;
}

.subNavigationMenuItem {
	@apply
	yst-block
	yst-text-sm
	yst-font-medium
}

.subNavigationMenuItemLink {
	@apply
	yst-flex
	yst-items-center
	yst-justify-center
	yst-border-b-4
	yst-border-slate-200
	yst-p-4
	lg:yst-p-6
	yst-translate-y-[1.5px];
	text-decoration: none !important;
	box-shadow: none !important;
	color: #64748b !important;

	&.active, &:hover {
		@apply
		yst-border-b-4
		yst-border-primary-400;
		color: #a61e69 !important;
	}
}
