@use "../../config/defaults.scss" as defaults;

.sidebar {
  flex: 0 0 300px;
  // Firefox needs this for user-email break word to work inside flex items.
  max-width: 300px;
  padding-left: 16px;

  @media screen and (max-width: defaults.$mediumBreakpoint ) {
    max-width: none;
    padding-right: 16px;
    overflow: hidden;
  }
}
