@use "./defaults.scss" as defaults;
@use "@yoast/style-guide/colors.scss" as colors;

.paper {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.whitePage {
  @extend .paper;
  background-color: colors.$color_white;
  padding: 16px 24px;
  margin-top: 24px;
}

.page {
  @extend .whitePage;
  background-color: colors.$color_white;
  display: flex;
  justify-content: space-between;

  @media screen and ( max-width: defaults.$mobileBreakpoint ) {
    display: block;
  }
}

.pageCard {
  @extend .page;
  margin: 0 8px 16px 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}
