@use "../../config/defaults.scss" as defaults;

.layout {
	display: flex;
	min-height: 100%;

	/**
	* Must be here to support ../App.css:288
	 */
	@media only screen and (max-width: 1024px) {
		padding-bottom: 3rem;
	}

	@media screen and (max-width: defaults.$mediumBreakpoint) {
		display: block;
		min-height: 0;
		overflow: hidden;
	}
}

.main {
	flex: 1 1 auto;
	background: var(--bg-color-offwhite);
	margin: 0 24px 0 48px;
	padding: 24px 0;
	// Firefox needs this for site-name break word to work.
	min-width: 0;

	@media screen and (max-width: defaults.$mediumBreakpoint) {
		margin: 0 16px 0 16px;
		padding: 24px 0 100px 0;
		position: relative;
		z-index: 0;
	}
}
