.sitesList {
	@apply yst-space-y-4;
}

.sitesListItem {
	@apply yst-flex yst-items-center yst-p-4 yst-border yst-border-slate-200 yst-rounded-md yst-shadow;
}

.siteIcon {
	@apply yst-w-6 yst-h-6 yst-mr-2 yst-text-slate-400;
}

.siteName {
	@apply yst-font-semibold;
}

.siteActions {
	@apply yst-flex yst-items-center yst-ml-auto yst-space-x-3;
}
