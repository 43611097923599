.sideMenu {
	.menuHeader {
		@apply
		yst-flex
		yst-items-center
		lg:yst-items-start
		yst-justify-between
		lg:yst-flex-col
		yst-mt-10
		yst-mb-6
		lg:yst-px-4
		lg:yst-space-y-10
		lg:yst-mb-6;
	}
}

nav {
	@apply
	yst-flex
	yst-flex-row
	yst-gap-2
	lg:yst-gap-0
	lg:yst-flex-col
	yst-overflow-x-auto
	yst-px-0.5;

	li {
		@apply yst-block;

		a {
			@apply
			yst-my-1.5;
		}

		[class~=active] {
			@apply
			yst-bg-white
			lg:yst-bg-slate-200;

			svg {
				@apply yst-text-slate-500;
			}

			&:hover {
				@apply yst-bg-slate-200 #{!important};
			}

			span {
				@apply yst-text-slate-900;
			}
		}
	}

	.menuItem,
	.menuItem[class~='yst-link'] {
		@apply
		yst-flex
		yst-items-center;

		svg {
			@apply
			yst-w-6
			yst-h-6
			yst-mr-3
			yst-text-slate-400;
		}
	}
}

.mobileMenu {
	display: none;
	@media screen and (max-width: 1024px){
		display: block;
	}
}

.desktopMenu {
	@apply
	yst-pb-1;
	display: block;

	@media screen and (max-width: 1024px){
		display: none;
	}
}
