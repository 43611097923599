.sitesPageNoSites {
	@apply yst-italic yst-mb-6 yst-text-slate-500;
}

.sitesPageActions {
	@apply yst-space-x-3 yst-mb-6;
}

.sitesPageActionsButton {
	@apply yst-gap-1;
}

.sitesPageIcon {
	@apply yst-w-4 yst-h-4;
}

.sitesPageAlert {
	@apply yst-mb-6;
}

.sitesPageContentWrapper{
	@apply	yst-max-w-xl;
}

.noResults {
	@apply yst-italic yst-mb-6 yst-text-slate-500;
}
