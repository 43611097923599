.contentWrapper {
	@apply
	yst-mt-6
	yst-space-y-4;

	pre {
		@apply
		yst-block
		yst-bg-slate-100
		yst-p-4
		yst-mt-1
		yst-rounded-sm
		yst-overflow-y-auto;
	}
}

.combinedWrapper {
	pre {
		@apply
		yst-inline
		yst-p-0;
	}
}

.buttonWrapper {
	@apply
	yst-flex
	yst-gap-2
	yst-justify-end
	yst-mt-6;
}
