@charset "UTF-8";

:root {
	/* Color definitions */
	--color-white: #FFFFFF;
	--color-black: #000000;

	--color-amber-100: #FEF3C7;
	--color-amber-300: #FCD34D;
	--color-amber-400: #FBBF24;
	--color-amber-500: #F59E0B;
	--color-amber-800: #92400E;
	--color-amber-900: #78350F;

	--color-blue-100: #DBEAFE;
	--color-blue-500: #3B82F6;
	--color-blue-600: #0066CD;
	--color-blue-800: #1E40AF;

	--color-gray-50: #F8F9FA;
	--color-gray-100: #F3F4F6;
	--color-gray-200: #E5E7Eb;
	--color-gray-300: #D1D5DB;
	--color-gray-400: #8C929F;
	--color-gray-500: #6B7280;
	--color-gray-600: #475569;
	--color-gray-700: #374151;
	--color-gray-800: #1F2937;
	--color-gray-900: #111827;

	--color-slate-100: rgb(241 245 249);

	--color-green-100: #DCFCE7;
	--color-green-500: #22C55E;
	--color-green-800: #166534;

	--color-red-100: #FEE2E2;
	--color-red-500: #EF4444;
	--color-red-800: #991B1B;

	--color-purple-50: #F9F1F6;
	--color-purple-100: #F3E5ED;
	--color-purple-200: #E6B8D2;
	--color-purple-300: #CD82AB;
	--color-purple-400: #B94986;
	--color-purple-500: #A61E69;
	--color-purple-600: #9A1660;
	--color-purple-700: #8F0F57;
	--color-purple-800: #83084E;
	--color-purple-900: #770045;

	--color-sky-blue-700: #0369a1;
	--color-wp-blue: #0073AA;

	/* Colors */
	--bg-alert-warning: var(--color-amber-100);
	--bg-alert-error: var(--color-red-100);
	--bg-color-white: var(--color-white);
	--bg-color-offwhite: var(--color-slate-100);
	--bg-color-purple: var(--color-purple-500);
	--bg-color-overlay: rgba(0, 0, 0, 0.6);
	--bg-input-disabled: var(--color-gray-300);
	--text-color-white: var(--color-white);
	--text-color-purple: var(--color-purple-500);
	--text-color-blue: var(--color-blue-600);
	--text-color-default: var(--color-gray-600);
	--text-color-dark: var(--color-gray-800);
	--text-color-error: var(--color-red-500);
	--checkmark-circle-green: var(--color-green-500);
	--border-color: var(--color-gray-200);
	--border-color-300: var(--color-gray-300);

	/* Fonts */
	--font-weight-400: 400;
	--font-weight-500: 500;
	--font-weight-600: 600;
	--font-weight-700: 700;
	--title-font-size: 18px;
	--text-font-size: 14px;
	--text-font-size-small: 12px;

	/* Spacing */
	--element-padding: 24px;
	--gap: 24px;

	/* Rounding */
	--border-radius: 8px;

	/* Shadows */
	--box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

button,
input,
optgroup,
select,
textarea {
	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	font-size: 100%;
}

.screen-reader-text {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	word-wrap: normal !important;
}

@media only screen and (max-width: 1024px) {
	#beacon-container .BeaconFabButtonFrame {
		top: auto !important;
		right: 12px !important;
		bottom: 72px !important;
	}
}
