.invoicesModalWrapper {
	@apply yst-w-auto sm:yst-w-[480px] md:yst-w-[640px];
}

.invoicesModalHeader {
	@apply -yst-mt-6 -yst-mx-6 yst-mb-6 yst-border-b yst-border-slate-200 yst-p-6 yst-space-y-4;
}

.downloadButtonsWrapper {
	 @apply yst-flex yst-items-center yst-justify-end yst-space-x-2;
}

.downloadButtonIcon {
	 @apply yst-w-4 yst-h-4 yst-mr-1;
 }

.tableHead {
	@apply yst-hidden md:yst-table-header-group;
}

.tableBody {
	@apply yst-border-t-transparent;

	td {
		@apply yst-flex md:yst-table-cell yst-flex-col yst-items-start yst-min-w-full md:yst-min-w-fit;

		&::before {
			content: attr(data-label);
			@apply yst-font-bold yst-text-slate-500 md:yst-hidden;
		}

		&:last-of-type::before {
			@apply yst-hidden;
		}
	}
}

.modalActions {
	@apply yst-flex yst-items-center yst-justify-end yst-mt-6;
}
