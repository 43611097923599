.platformSelect {
	@apply
	yst-w-full lg:yst-w-2/3 xl:yst-w-1/2
	yst-flex
	yst-flex-col
	yst-gap-1
	yst-mt-8;

	.selectAndConfirm {
		@apply
		yst-flex
		yst-items-end
		yst-gap-2;
	}

	.select {
		@apply
		yst-grow;
	}
}
