.transactionStatusCell {
	@apply yst-capitalize;
}

.tableHead {
	@apply yst-hidden md:yst-table-header-group;
}

.tableBody {
	@apply yst-border-t-transparent;

	td {
		@apply yst-flex md:yst-table-cell yst-flex-col yst-items-start yst-min-w-full md:yst-min-w-fit;

		&::before {
			content: attr(data-label);
			@apply yst-font-bold yst-text-slate-500 md:yst-hidden;
		}

		&:last-of-type::before {
			@apply yst-hidden;
		}
	}
}

.ordersPageContentWrapper {
	@apply yst-w-full;
}

.ordersPageAlert {
	@apply yst-mb-6;
}

.noResults {
	@apply yst-italic yst-mb-6 yst-text-slate-500;
}

.ordersPageLink {
	@apply yst-inline-flex yst-items-center;
}

.ordersPageLinkIcon {
	@apply yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5;
}

.search {
	@apply yst-w-full lg:yst-w-2/3 xl:yst-w-1/2;
}
