.actionButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap);
  margin-top: var(--gap);
}

.upgradeSubscriptionCard {
  max-width: 600px;
  margin: auto;
  font-size: 1em;

  p {
    margin: 0;
  }
}

.upgradePriceHeader {
  max-width: 600px;
  font-size: 1em;
  margin-bottom: 3px;

  p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }
}

.productInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 600px;
  font-size: 1em;
  margin-bottom: 24px;

  img {
    width: 48px;
    margin: 0 16px 0 0;
  }
}

.refundWarning {
  max-width: 600px;
  font-size: 1em;
  margin-bottom: 3px;
}

.upgradeSubscriptionCard {
  margin: 1em 0;
}

.subtitle {
  margin: 1em 0;
}