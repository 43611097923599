@use "../styles.scss";
@use "../../../config/defaults.scss" as defaults;

.layoutWhite {
  @apply yst-bg-white;
  height: 100%;
  color: #404040;
}

.main {
  &.white  {
    @apply yst-bg-white;
    margin: 0;
    padding: 0 0 24px;

    @media screen and (max-width: defaults.$mediumBreakpoint) {
      position: relative;
      margin: 0;
      padding: 0 0 24px;
      z-index: 0;
    }
  }
}
